export const LINK = {
    home: {
        url: '/',
        title: 'Home',

    },
    order: {
        url: '/order/',
        title: 'Order',
        type: 'subMenu',
        icon:'CheckIcon',
        subMenu: [
            {
                url: '/order/view',
                title: 'View Order',
                icon:'ViewIcon',
            },
            {
                url: '/order/Add',
                title: 'Add New Customer Order',
                icon:'SmallAddIcon',
            }
        ]
    },
    design: {
        url: '/design/',
        title: 'My Design',
        type: 'subMenu',
        icon:'CopyIcon',
        subMenu: [
            {
                url: '/order/view',
                title: 'View Design',
                icon:'ViewIcon',
            },
            {
                url: '/order/Add',
                title: 'Add Design',
                icon:'SmallAddIcon',
            }
        ]
    },
    manufacturer: {
        url: '/manufacturer/',
        title: 'Manufacturer',
        type: 'subMenu',
        subMenu: [
            {
                url: '/manufacturer/view',
                title: 'View Manufacturer',
                icon:'ViewIcon',
            },
        ]
    },
    traders: {
        url: '/traders/',
        title: 'Traders',
        type: 'subMenu',
        subMenu: [
            {
                url: '/traders/view',
                title: 'View Trader',
                icon:'ViewIcon',
            }
        ]
    },
    stock: {
        url: '/stock/',
        title: 'Stock',
        type: 'subMenu',
        subMenu: [
            {
                url: '/stock/view',
                title: 'View Stock',
                icon:'ViewIcon',
            },
            {
                url: '/stock/add',
                title: 'Add Stock',
                icon:'SmallAddIcon'
            }
        ]
    },
    setting: {
        url: '/setting/',
        title: 'Setting',
        type: 'subMenu',
        icon:'SettingsIcon',
        subMenu: [
            {
                url: '/setting/category',
                title: 'Product Category',
                icon:'EditIcon'
            },
            {
                url: '/setting/product',
                title: 'Manage Product',
                icon:'EditIcon'
            },
            {
                url: '/setting/user',
                title: 'User Setting',
                icon:'EditIcon'
            },
            {
                url: '/setting/order',
                title: 'Order Setting',
                icon:'EditIcon'
            }
        ]
    },

    services: {
        url: '/services',
        title: 'Services'
    },
    service: {
        url: '/service/',
        title: 'Service'
    },
    userProfile: {
        url: '/user',
        title: 'User'
    },
    userSetting: {
        url: '/settings',
        title: 'Settings'
    },
    dashboard: {
        url: '/dashboard',
        title: 'Dashboard'
    }
}
export const SIDE_MENU = [
    LINK.order,
    LINK.manufacturer,
    LINK.design,
    LINK.stock,
    LINK.traders,
    LINK.setting,
]
export const MAIN_MENU = [
    LINK.home,
    LINK.services,
]
export const USER_MENU = [
    LINK.userProfile,
    LINK.dashboard,
    LINK.userSetting
]