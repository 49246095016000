import React, { useState } from 'react';
import {
    Flex,
    Box,
    Heading,
    Spacer,
    Button,
    IconButton,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    List,
    ListItem,
    ListIcon,
    Text
} from '@chakra-ui/react';
import {
    Link
} from "react-router-dom";
import { SIDE_MENU } from '../../../../routes/routesConstant'
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import * as Icons from '@chakra-ui/icons';

const Header = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef();
    console.log(SIDE_MENU, 'SIDE_MENU')
    return (
        <Flex
            as="header"
            align="center"
            justify="space-between"
            py="0"
            px="6"
            bg="blue.500"
            color="white"
        >
            {
                isOpen ? <IconButton
                    icon={<CloseIcon />}
                    aria-label="menu-close"
                    colorScheme="white"
                    variant="outline"
                    onClick={onOpen}
                /> : <IconButton
                    icon={<HamburgerIcon />}
                    aria-label="Menu"
                    colorScheme="white"
                    variant="outline"
                    onClick={onOpen}
                />
            }

            <Box p="5">
                <Heading as="h1" size="lg">
                    My App
                </Heading>
            </Box>

            <Spacer />
            <Drawer
                isOpen={isOpen}
                placement="top"
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Menus</DrawerHeader>
                    <DrawerBody>
                        <List styleType="none" fontSize="md">
                            {
                                SIDE_MENU.map((menu, index) => {
                                    return (<ListItem key={menu.url}>
                                        <Box borderBottom="1px" borderColor="gray.200" py={1}>
                                            <Flex  alignItems="center"  py={2}>
                                                <ListIcon as={Icons[menu.icon]} color="blue.300" />
                                               <Text fontWeight="bold" ml={2}>{menu.title}</Text>
                                            </Flex>
                                            <List ml={4}>
                                                {
                                                    menu.subMenu.map((subLink,index)=>{
                                                        return(<ListItem key={subLink.url}>
                                                            <Flex  alignItems="center" py={1}>
                                                              <ListIcon as={Icons[subLink.icon]} color="blue.300" />
                                                                <Link to={subLink.url} onClick={onClose} className='noUnderline'><Text ml={2}>{subLink.title}</Text></Link>
                                                            </Flex>
                                                        </ListItem>)
                                                    })
                                                }
                                                
                                            </List>
                                        </Box>
                                    </ListItem>)

                                })
                            }


                        </List>


                        {/* Your menu items can go here */}
                        {/* <Button variant="ghost">Item 1</Button>
            <Button variant="ghost">Item 2</Button>
            <Button variant="ghost">Item 3</Button> */}
                    </DrawerBody>
                    <DrawerFooter>
                        {/* Any additional footer content can go here */}
                       
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </Flex>
    );
};

export default Header;
