import logo from './logo.svg';
import { ChakraProvider } from '@chakra-ui/react'
import PageRouter from './routes'
function App() {
  return (
    <div className="App">
      <ChakraProvider>
        <PageRouter />
      </ChakraProvider>
    </div>
  );
}

export default App;
