import React from 'react';
import { Flex, Spinner } from '@chakra-ui/react';

const FullPageLoader = () => {
  return (
    <Flex
      position="fixed"
      top="0"
      left="0"
      width="100%"
      height="100%"
      backgroundColor="rgba(255, 255, 255, 0.8)" // Semi-transparent white background
      zIndex="9999" // Ensure it's above other content
      justifyContent="center"
      alignItems="center"
    >
      <Spinner size="xl" color="blue.500" />
    </Flex>
  );
};

export default FullPageLoader;
