
export async function getOrderForm() {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(mock)
        }, 3000);
    });
}
const mock= {
    status: 200,
    isSuccess:true,
    forms: [
        {
            id: '1',
            slug: 'door',
            title: 'Door Size',
            fields: [
                {
                    title: 'Door Type',
                    name:'doorType',
                    type: 'select',
                    options: [
                        { key: 'wooden', value: 'wooden' },
                        { key: 'flash-door', value: 'flash-door' },
                    ],
                    isRequired: true,
                },
                {
                    title: 'Wood Type',
                    type: 'select',
                    name:'woodType',
                    options: [
                        { key: 'pine', value: 'Pine' },
                        { key: 'hardwood', value: 'hardwood' },
                        { key: 'sagwan', value: 'sagwan' },
                        { key: 'kapoor', value: 'kapoor' },
                    ],
                    isRequired: true,
                },
                {
                    title: 'Door Height(inch/suth)',
                    name:'doorHeight',
                    type: 'text',
                    isRequired: true,
                },
                {
                    title: 'Door Width (inch/suth)',
                    name:'doorWidth',
                    type: 'text',
                    isRequired: true,
                },
                {
                    title: 'Opning Side',
                    type: 'radio',
                    name:'opningSide',
                    options: [
                        { key: 'left', value: 'left' },
                        { key: 'right', value: 'right' },
                    ],
                    isRequired: true,
                },
                {
                    title: 'Fitting Side',
                    type: 'radio',
                    name:'fittingSide',
                    options: [
                        { key: 'in-side', value: 'in-side' },
                        { key: 'out-side', value: 'out-side' },
                    ],
                    isRequired: true,
                },
            ]
        }
    ]
}