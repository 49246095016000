import * as React from 'react';
import {
    Box,
    Heading,
    Divider,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td
} from '@chakra-ui/react'
import { usePageLoader } from '../../../hooks/PageLoaderProvider'
function OrderView() {
    const { hideLoader } = usePageLoader()

    React.useEffect(function () {
        setTimeout(() => {
            hideLoader()
            console.log('usePageLoader()')
        }, 2000)
    }, [])

    return (
        <Box mx="auto" p={6} m={10} borderWidth="1px" borderRadius="lg">
            <TableContainer>
                <Table size='sm'>
                    <Thead>
                        <Tr>
                            <Th>Customer name</Th>
                            <Th>order Title/Id</Th>
                            <Th isNumeric>No of items</Th>
                            <Th isNumeric>No of items</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td>inches</Td>
                            <Td>millimetres (mm)</Td>
                            <Td isNumeric>25.4</Td>
                        </Tr>
                        <Tr>
                            <Td>feet</Td>
                            <Td>centimetres (cm)</Td>
                            <Td isNumeric>30.48</Td>
                        </Tr>
                        <Tr>
                            <Td>yards</Td>
                            <Td>metres (m)</Td>
                            <Td isNumeric>0.91444</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default OrderView;
