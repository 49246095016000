import * as React from 'react';
import { usePageLoader } from '../../../hooks/PageLoaderProvider'
function Home() {
    const { hideLoader } = usePageLoader()

    React.useEffect(function () {
        setTimeout(() => {
            hideLoader()
            console.log('usePageLoader()')
        }, 2000)
    }, [])
    
    return (
        <div className="App">
            welcome in home page
        </div>
    );
}

export default Home;
