import * as React from 'react';
import { usePageLoader } from '../../../../hooks/PageLoaderProvider'
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Box,
    Heading,
    Divider,
    Stack,
    Input,
    Button,
    Select,
    RadioGroup,
    Radio
} from '@chakra-ui/react'

import { getOrderForm } from '../../../../services/form'

function SingleOrderForm({handleFromSubmit}) {
    const { hideLoader } = usePageLoader()
    const [forms, setForms] = React.useState([])
    React.useEffect(function () {
        callServices()
    }, [])

    async function callServices() {
        const { isSuccess, forms } = await getOrderForm()
        if (isSuccess) {
            console.log(forms, 'rest')
            setForms(forms)
            hideLoader()
        }
    }
    function handleSubmit(e) {
        e.preventDefault()
        handleFromSubmit()
    }
    function handleChange() {

    }
    const formData = {}
    return (
        <div >
            {forms.map((form,) => {

                return (<Box maxW="xl" mx="auto"  p={6} borderWidth="1px" borderRadius="lg">
                    <Heading mb={8}>{form.title}</Heading>
                    <Divider mb={10} />
                    <form onSubmit={handleSubmit}>

                        <Stack spacing={4} key={form.slug}>
                            {
                                form.fields.map((field, fieldIndex) => <FormControl>
                                    <FormLabel htmlFor={field.name}>{field.title}</FormLabel>
                                    {
                                        field.type === 'select' ?
                                            <Select placeholder="Please select">
                                                {
                                                    field.options.map((option) =>
                                                        <option key={option.value} value={option.value}>{option.key}</option>
                                                    )
                                                }

                                            </Select>
                                            :
                                            field.type === 'radio' ? <RadioGroup onChange={handleChange} >
                                                <Stack direction='row'>
                                                    {
                                                        field?.options?.map((option) =>
                                                            <Radio key={option.value} value={option.value}>{option.key}</Radio>
                                                        )
                                                    }
                                                </Stack>
                                            </RadioGroup> :
                                                <Input
                                                    type={field.type}
                                                    id={field.name}
                                                    name={field.name}
                                                    value={formData.fullName}
                                                    onChange={handleChange}
                                                    required={field.isRequired}
                                                />
                                    }

                                </FormControl>)
                            }


                          
                            <Button type="submit">Save Product 1</Button>
                        </Stack>
                    </form>
                </Box>
                )
            })
            }
        </div>
    );
}

export default SingleOrderForm;
