
import * as React from 'react';
import {
    FormControl,
    FormLabel,
    Box,
    Heading,
    Divider,
    Stack,
    Input,
    Button,
    Select,
    Checkbox,
} from '@chakra-ui/react'


function ClientInfoForm({processNextStep}) {
    const [isNewUser, setIsNewUser] = React.useState(true)

    function handleChangeNewUser({ target }) {
        setIsNewUser(target.checked)
    }

    function handleSubmit() {
        processNextStep({id:'some-id',name:'kartik furniture'})
    }
    function handleChange() {

    }
    return (
        <Box maxW="xxl" mx="auto" m={10} p={6} borderWidth="1px" borderRadius="lg">
            <Heading mb={8}>Create New Order</Heading>
            <Divider mb={10} />
            <Box>
                <Stack spacing={4} >
                    <FormControl>
                        <FormLabel htmlFor="newClient">New Buyer</FormLabel>
                        <Checkbox checked={isNewUser} defaultChecked={true} onChange={handleChangeNewUser} >
                            New Buyer
                        </Checkbox>
                    </FormControl>

                </Stack>
            </Box>
            {
                isNewUser ?

                    <form onSubmit={handleSubmit}>

                        <Divider m={5} />
                        <Stack spacing={4} >
                            <FormControl>
                                <FormLabel htmlFor="newClient"> Client Name</FormLabel>
                                <Input
                                    type="text"
                                    id="clientName"
                                    name="clientName"
                                    value=""
                                    onChange={handleChange}

                                />
                            </FormControl>
                        </Stack>
                        <Stack spacing={4} mt={5} >
                            <FormControl>
                                <FormLabel htmlFor="mobile">Mobile Number</FormLabel>
                                <Input
                                    type="text"
                                    id="mobile"
                                    name="mobile"
                                    value=""
                                    onChange={handleChange}

                                />
                            </FormControl>
                        </Stack>
                        <Stack spacing={4} mt={5} >
                            <FormControl>
                                <FormLabel htmlFor="mobile">Address</FormLabel>
                                <Input
                                    type="text"
                                    id="address"
                                    name="address"
                                    value=""
                                    onChange={handleChange}

                                />
                            </FormControl>
                        </Stack>
                        <Button type="submit">Save</Button>
                    </form> :
                    <form onSubmit={handleSubmit}>

                        <Divider m={5} />
                        <Stack spacing={4} >
                            <FormControl>
                                <FormLabel htmlFor="clientName"> Client Name</FormLabel>
                                <Select name="clientName" placeholder="Please select">
                                    <option value="client one">client one</option>
                                    <option value="client one">client two</option>
                                    <option value="client one">client three</option>
                                    <option value="client one">client four</option>
                               </Select>
                            </FormControl>
                            <Button type="submit">Save</Button>
                        </Stack>

                    </form>
            }

        </Box>
    );
}

export default ClientInfoForm;
