import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import { PageLoaderProvider } from '../hooks/PageLoaderProvider'
 import Layout from '../view/shared/layout'
import Home from '../view/pages/Home'
 import OrderView from '../view/pages/OrderView'
 import OrderAdd from '../view/pages/OrderAdd'
export default function PageRouter() {
    return (
        <Router>
            <PageLoaderProvider data={{isLoadingDefault:true}}>
                <Layout>
                    <div>


                        <Routes>
                            <Route  path="/" element={<Home />} />
                            <Route exact path="/order/view" element={<OrderView />} />
                            <Route path="/order/add" element={<OrderAdd />} />
                        </Routes>
                    </div>
                </Layout>
            </PageLoaderProvider>
        </Router>
    );
}
