import * as React from 'react';
import { usePageLoader } from '../../../hooks/PageLoaderProvider'
import SingleOrderForm from './singleOrderForm'
import ClientInfoForm from './clientInfo'
import ModalBox from '../../shared/modalBox'
import ViewProductList from './viewProductList'
import {
    Box,
    Flex, Button
} from '@chakra-ui/react'
import { getOrderForm } from '../../../services/form'

const demoData = { type: "Door", id: 'some-door-id', prince: "100Rs", height: "80 inch", width: "32.4 inch" }
const formTypes = [{ id: '1', title: 'Door', type:"Door" }, { id: '2', title: 'Window'}, { id: '3', title: 'Door Frame' }, { id: '4', title: 'Aluminum' }]

function OrderAdd() {
    const { hideLoader } = usePageLoader()
    const [forms, setForms] = React.useState([])
    const [clientInfo, setClientInfo] = React.useState({})
    const [isOpen, setIsOpen] = React.useState(false);
    const [formId, setFormId] = React.useState(false);
    const [cartInfo, setCartInfo] = React.useState([]);

    React.useEffect(function () {
        callServices()
    }, [])


    async function callServices() {
        const { isSuccess, forms } = await getOrderForm()
        if (isSuccess) {
            setForms(forms)
            hideLoader()
        }
    }

    function handleFromSubmit() {
        setCartInfo([...cartInfo ,{...demoData,formId}])
        handleClose()
    }
    const handleClose = () => setIsOpen(false);

    const openAddForm = (id) => {
        setFormId(id)
        setIsOpen(true)
    }

    function setSelectedClientInfo(info) {
        setClientInfo(info)
    }
    const formData = {}
    const hasClient = !!clientInfo?.id
    return (
        <Box mt={1}>
            {
                hasClient ?
                    <Box>
                        <Flex
                            as="nav"
                            align="center"
                            justify="end"
                            wrap="wrap"
                            padding="2"
                            bg="blue.500"
                            color="white"
                        >

                            <Box
                                flexBasis={{ base: '100%', md: 'auto' }}
                            >
                                <Flex align="center" justify="space-between" wrap="wrap"
                                >
                                    {
                                        formTypes.map((types)=> <Button key={types.id} m={2} variant='outline' onClick={() => { openAddForm(types.id) }} >Add {types.title}</Button>)
                                    }
                                    </Flex>
                            </Box>



                        </Flex>
                        <ViewProductList cart={cartInfo} productTypes={formTypes} />
                    </Box>
                    : null
            }

            {
                hasClient ? null : <ClientInfoForm processNextStep={setSelectedClientInfo} />
            }
            <ModalBox heading="Add Product details on Order" isOpen={isOpen} onClose={handleClose} >
                <SingleOrderForm handleFromSubmit={handleFromSubmit} />
            </ModalBox>
        </Box>
    );
}

export default OrderAdd;
