import * as React from 'react';
import {
    Box,
    Heading,
    Divider,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td
} from '@chakra-ui/react'

function ViewProductList({ cart, productTypes }) {
    function filterByType(id) {
        return cart?.filter(({ formId }) => formId === id)
    }
    return (
        <Box mx="auto" p={6} m={10} borderWidth="1px" borderRadius="lg">
            {
                productTypes.map((types) =>
                    <TableContainer>
                        <Heading as='h6' size='md' mb={2}>{types.title}</Heading>
                        <Divider mb={2} />
                        <Table size='sm' mb={10}>
                            <Thead>
                                <Tr>
                                    <Th>No</Th>
                                    <Th>Type</Th>
                                    <Th>Height</Th>
                                    <Th>Width</Th>
                                    <Th>Opning Side</Th>
                                    <Th>Fitting Side</Th>
                                    <Th>Price</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {
                                    filterByType(types.id).map((item,index) => <Tr>
                                        <Td>{index}</Td>
                                        <Td>{}</Td>
                                        <Td>inches</Td>
                                        <Td>inches</Td>
                                        <Td>inches</Td>
                                        <Td>inches</Td>
                                        <Td>inches</Td>
                                    </Tr>)
                                }


                            </Tbody>
                        </Table>
                    </TableContainer>
                )
            }
        </Box>
    );
}

export default ViewProductList;
